<template>
  <svg width="74" height="74" viewBox="0 0 74 74" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M40.5 67H16.5C9.37004 67 3.57004 60.94 3.57004 53.5C3.57004 46.06 9.34004 40 16.47 40H57.57C66.35 40 73.5 32.6 73.5 23.5C73.5 14.4 66.35 7 57.56 7H26.5C26.1022 7 25.7207 7.15804 25.4394 7.43934C25.1581 7.72065 25 8.10218 25 8.5C25 8.89782 25.1581 9.27935 25.4394 9.56066C25.7207 9.84196 26.1022 10 26.5 10H57.56C64.69 10 70.49 16.06 70.49 23.5C70.49 30.94 64.69 37 57.56 37H16.47C7.69004 37 0.540039 44.4 0.540039 53.5C0.540039 62.6 7.68004 70 16.47 70H40.47C40.8679 70 41.2494 69.842 41.5307 69.5607C41.812 69.2794 41.97 68.8978 41.97 68.5C41.97 68.1022 41.812 67.7206 41.5307 67.4393C41.2494 67.158 40.8679 67 40.47 67H40.5Z"
      fill="url(#paint0_linear_81_36)"
    />
    <path
      d="M8.5 17C10.1811 17 11.8245 16.5015 13.2223 15.5675C14.6202 14.6335 15.7096 13.306 16.353 11.7528C16.9963 10.1996 17.1646 8.49057 16.8367 6.84174C16.5087 5.1929 15.6992 3.67834 14.5104 2.4896C13.3217 1.30085 11.8071 0.491303 10.1583 0.163329C8.50943 -0.164645 6.80036 0.00368291 5.24719 0.647028C3.69402 1.29037 2.3665 2.37984 1.43251 3.77766C0.498516 5.17547 0 6.81886 0 8.5C0.00264654 10.7535 0.89903 12.914 2.49252 14.5075C4.086 16.101 6.24647 16.9974 8.5 17ZM8.5 3C9.5878 3 10.6512 3.32257 11.5556 3.92692C12.4601 4.53127 13.1651 5.39025 13.5813 6.39524C13.9976 7.40024 14.1065 8.5061 13.8943 9.573C13.6821 10.6399 13.1583 11.6199 12.3891 12.3891C11.6199 13.1583 10.6399 13.6821 9.573 13.8943C8.5061 14.1065 7.40023 13.9976 6.39524 13.5813C5.39025 13.1651 4.53126 12.4601 3.92692 11.5556C3.32257 10.6512 3 9.5878 3 8.5C3.00264 7.04213 3.58295 5.64471 4.61383 4.61384C5.64471 3.58296 7.04212 3.00265 8.5 3Z"
      fill="url(#paint1_linear_81_36)"
    />
    <path
      d="M68.5899 57.4797C68.3086 57.1988 67.9274 57.041 67.5299 57.041C67.1324 57.041 66.7512 57.1988 66.4699 57.4797L60.6799 63.2697L54.8899 57.4797C54.6055 57.2147 54.2295 57.0705 53.8409 57.0773C53.4523 57.0842 53.0815 57.2416 52.8067 57.5164C52.5318 57.7913 52.3744 58.162 52.3676 58.5507C52.3607 58.9393 52.5049 59.3153 52.7699 59.5997L58.5599 65.3897L52.7699 71.1797C52.6225 71.317 52.5043 71.4826 52.4223 71.6666C52.3404 71.8506 52.2963 72.0492 52.2927 72.2506C52.2892 72.4521 52.3262 72.6521 52.4017 72.8389C52.4771 73.0257 52.5894 73.1953 52.7318 73.3378C52.8743 73.4802 53.0439 73.5925 53.2307 73.6679C53.4175 73.7434 53.6175 73.7804 53.8189 73.7769C54.0203 73.7733 54.219 73.7292 54.403 73.6473C54.587 73.5653 54.7526 73.4471 54.8899 73.2997L60.6799 67.5097L66.4699 73.2997C66.7542 73.5647 67.1303 73.7089 67.5189 73.702C67.9075 73.6952 68.2783 73.5378 68.5531 73.2629C68.828 72.9881 68.9854 72.6173 68.9922 72.2287C68.9991 71.8401 68.8549 71.464 68.5899 71.1797L62.7999 65.3897L68.5899 59.5997C68.8708 59.3184 69.0286 58.9372 69.0286 58.5397C69.0286 58.1422 68.8708 57.7609 68.5899 57.4797Z"
      fill="url(#paint2_linear_81_36)"
    />
    <defs>
      <linearGradient id="paint0_linear_81_36" x1="59.6029" y1="-5.80881" x2="42.6192" y2="71.2343" gradientUnits="userSpaceOnUse">
        <stop stop-color="#E5683B" />
        <stop offset="1" stop-color="#FF7F00" />
      </linearGradient>
      <linearGradient id="paint1_linear_81_36" x1="13.7619" y1="-3.45635" x2="8.53707" y2="17.0095" gradientUnits="userSpaceOnUse">
        <stop stop-color="#E5683B" />
        <stop offset="1" stop-color="#FF7F00" />
      </linearGradient>
      <linearGradient id="paint2_linear_81_36" x1="65.8407" y1="53.6383" x2="60.697" y2="73.7864" gradientUnits="userSpaceOnUse">
        <stop stop-color="#E5683B" />
        <stop offset="1" stop-color="#FF7F00" />
      </linearGradient>
    </defs>
  </svg>
</template>
