<template>
  <svg width="65" height="76" viewBox="0 0 65 76" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M62.8333 0H1.34766C0.602854 0 0 0.602853 0 1.34766V74.6523C0 75.3962 0.602854 76 1.34766 76H62.8343C63.5782 76 64.1819 75.3962 64.1819 74.6523V1.34766C64.181 0.602853 63.5773 0 62.8333 0ZM61.4857 73.3038H2.69532V2.69532H61.4866L61.4857 73.3038Z"
      fill="url(#paint0_linear_81_25)"
    />
    <path
      d="M9.69849 29.014H54.8901C55.6349 29.014 56.2377 28.4103 56.2377 27.6664V9.28516C56.2377 8.54035 55.6349 7.9375 54.8901 7.9375H9.69849C8.95368 7.9375 8.35083 8.54035 8.35083 9.28516V27.6655C8.35083 28.4103 8.95368 29.014 9.69849 29.014ZM11.0462 10.6337H53.5424V26.3187H11.0462V10.6337Z"
      fill="url(#paint1_linear_81_25)"
    />
    <path
      d="M19.3246 35.5732H10.1731C9.42829 35.5732 8.82544 36.177 8.82544 36.9209V46.0733C8.82544 46.8172 9.42829 47.421 10.1731 47.421H19.3246C20.0694 47.421 20.6723 46.8172 20.6723 46.0733V36.9209C20.6723 36.177 20.0685 35.5732 19.3246 35.5732ZM17.977 44.7257H11.5208V38.2686H17.977V44.7257Z"
      fill="url(#paint2_linear_81_25)"
    />
    <path
      d="M19.3246 53.3076H10.1731C9.42829 53.3076 8.82544 53.9114 8.82544 54.6553V63.8068C8.82544 64.5507 9.42829 65.1545 10.1731 65.1545H19.3246C20.0694 65.1545 20.6723 64.5507 20.6723 63.8068V54.6553C20.6723 53.9105 20.0685 53.3076 19.3246 53.3076ZM17.977 62.4591H11.5208V56.002H17.977V62.4591Z"
      fill="url(#paint3_linear_81_25)"
    />
    <path
      d="M54.415 35.5732H45.2644C44.5196 35.5732 43.9167 36.177 43.9167 36.9209V46.0733C43.9167 46.8172 44.5196 47.421 45.2644 47.421H54.4159C55.1607 47.421 55.7636 46.8172 55.7636 46.0733V36.9209C55.7627 36.177 55.1598 35.5732 54.415 35.5732ZM53.0674 44.7257H46.6121V38.2686H53.0683L53.0674 44.7257Z"
      fill="url(#paint4_linear_81_25)"
    />
    <path
      d="M54.415 53.3076H45.2644C44.5196 53.3076 43.9167 53.9114 43.9167 54.6553V63.8068C43.9167 64.5507 44.5196 65.1545 45.2644 65.1545H54.4159C55.1607 65.1545 55.7636 64.5507 55.7636 63.8068V54.6553C55.7627 53.9105 55.1598 53.3076 54.415 53.3076ZM53.0674 62.4591H46.6121V56.002H53.0683L53.0674 62.4591Z"
      fill="url(#paint5_linear_81_25)"
    />
    <path
      d="M37.1703 35.2725H28.0215C27.2767 35.2725 26.6738 35.8762 26.6738 36.6201V45.7725C26.6738 46.5164 27.2767 47.1202 28.0215 47.1202H37.1703C37.9151 47.1202 38.518 46.5164 38.518 45.7725V36.6201C38.518 35.8762 37.9142 35.2725 37.1703 35.2725ZM35.8227 44.4249H29.3692V37.9678H35.8227V44.4249Z"
      fill="url(#paint6_linear_81_25)"
    />
    <path
      d="M37.1703 53.0049H28.0215C27.2767 53.0049 26.6738 53.6086 26.6738 54.3525V63.5059C26.6738 64.2498 27.2767 64.8535 28.0215 64.8535H37.1703C37.9151 64.8535 38.518 64.2498 38.518 63.5059V54.3525C38.518 53.6086 37.9142 53.0049 37.1703 53.0049ZM35.8227 62.1573H29.3692V55.6993H35.8227V62.1573Z"
      fill="url(#paint7_linear_81_25)"
    />
    <defs>
      <linearGradient id="paint0_linear_81_25" x1="10.5" y1="-14" x2="61" y2="92" gradientUnits="userSpaceOnUse">
        <stop stop-color="#E5683B" />
        <stop offset="1" stop-color="#FF8011" />
      </linearGradient>
      <linearGradient id="paint1_linear_81_25" x1="16.185" y1="4.05498" x2="22.3778" y2="39.0267" gradientUnits="userSpaceOnUse">
        <stop stop-color="#E5683B" />
        <stop offset="1" stop-color="#FF8011" />
      </linearGradient>
      <linearGradient id="paint2_linear_81_25" x1="10.7636" y1="33.3908" x2="17.7848" y2="50.8407" gradientUnits="userSpaceOnUse">
        <stop stop-color="#E5683B" />
        <stop offset="1" stop-color="#FF8011" />
      </linearGradient>
      <linearGradient id="paint3_linear_81_25" x1="10.7636" y1="51.1253" x2="17.7839" y2="68.5743" gradientUnits="userSpaceOnUse">
        <stop stop-color="#E5683B" />
        <stop offset="1" stop-color="#FF8011" />
      </linearGradient>
      <linearGradient id="paint4_linear_81_25" x1="45.8549" y1="33.3908" x2="52.8761" y2="50.8407" gradientUnits="userSpaceOnUse">
        <stop stop-color="#E5683B" />
        <stop offset="1" stop-color="#FF8011" />
      </linearGradient>
      <linearGradient id="paint5_linear_81_25" x1="45.8549" y1="51.1253" x2="52.8752" y2="68.5743" gradientUnits="userSpaceOnUse">
        <stop stop-color="#E5683B" />
        <stop offset="1" stop-color="#FF8011" />
      </linearGradient>
      <linearGradient id="paint6_linear_81_25" x1="28.6115" y1="33.09" x2="35.6339" y2="50.5389" gradientUnits="userSpaceOnUse">
        <stop stop-color="#E5683B" />
        <stop offset="1" stop-color="#FF8011" />
      </linearGradient>
      <linearGradient id="paint7_linear_81_25" x1="28.6115" y1="50.8222" x2="35.6348" y2="68.2721" gradientUnits="userSpaceOnUse">
        <stop stop-color="#E5683B" />
        <stop offset="1" stop-color="#FF8011" />
      </linearGradient>
    </defs>
  </svg>
</template>
