<template>
  <div class="reviews__item">
    <div class="reviews__img-wrapper">
      <img :src="props.data.photo" :alt="props.data.title" class="reviews__img" />
    </div>
    <div class="reviews__content">
      <h3 class="reviews__title">
        {{ props.data.title }}
      </h3>
      <p class="reviews__text card-text">
        {{ props.preview ? props.data.textPreview : props.data.text }}
      </p>
      <button v-if="props.preview" class="reviews__btn" @click="() => emits('open-info', props.data)">читать весь отзыв</button>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { IData } from '~/components/blocks/types';
interface IProps {
  data: IData;
  preview?: boolean;
}

interface IEmits {
  (event: 'open-info', data: IData): void;
}
const emits = defineEmits<IEmits>();

const props = defineProps<IProps>();
</script>

<style scoped lang="scss">
.reviews {
  &__item {
    position: relative;
    padding-top: 40px;
    height: 100%;
    box-sizing: border-box;
  }

  &__title {
    font-size: 20px;
    font-weight: var(--bold);
    line-height: 30px;
  }

  &__content {
    background-color: var(--white);
    padding: 65px 40px 20px;
    border-radius: 32px;
    display: flex;
    flex-direction: column;
    gap: 26px;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    text-align: left;
    font-weight: 400;
    color: #2a3852;
  }

  &__text {
    color: #2a3852;
  }

  &__img-wrapper {
    width: 80px;
    height: 80px;
    min-width: 80px;
    border-radius: 50%;
    padding: 2px;
    background: linear-gradient(92.76deg, #fe9940 0.83%, #cc3535 110.14%);
    position: absolute;
    top: 0;
    left: 33px;
  }

  &__img {
    border-radius: 50%;
    width: 100%;
    height: 100%;
  }

  &__btn {
    font-size: 15px;
    line-height: 30px;
    text-decoration: underline;
    cursor: pointer;
    padding: 0;
    background-color: transparent;
    margin-top: auto;
    align-self: flex-end;
  }
}
</style>
