<template>
  <svg width="98" height="68" viewBox="0 0 98 68" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M74.875 64.6403H3.23202V18.3148H73.259V25.2744C73.259 25.703 73.4293 26.114 73.7323 26.4171C74.0354 26.7201 74.4464 26.8904 74.875 26.8904C75.3036 26.8904 75.7147 26.7201 76.0177 26.4171C76.3208 26.114 76.491 25.703 76.491 25.2744V1.57291C76.4798 1.15183 76.3046 0.751783 76.0028 0.457981C75.7009 0.164179 75.2963 -0.000149694 74.875 1.02322e-07H1.61601C1.18742 1.02322e-07 0.776378 0.170258 0.473318 0.473319C0.170257 0.776379 0 1.18742 0 1.61601V66.2563C0 66.6849 0.170257 67.096 0.473318 67.399C0.776378 67.7021 1.18742 67.8723 1.61601 67.8723H74.875C75.3036 67.8723 75.7147 67.7021 76.0177 67.399C76.3208 67.096 76.491 66.6849 76.491 66.2563C76.491 65.8277 76.3208 65.4167 76.0177 65.1136C75.7147 64.8106 75.3036 64.6403 74.875 64.6403ZM73.259 3.23202V15.0827H3.23202V3.23202H73.259Z"
      fill="url(#paint0_linear_42_1969)"
    />
    <path
      d="M8.61869 10.773H15.0827C15.5113 10.773 15.9224 10.6028 16.2254 10.2997C16.5285 9.99665 16.6987 9.58562 16.6987 9.15702C16.6987 8.72843 16.5285 8.31739 16.2254 8.01433C15.9224 7.71127 15.5113 7.54102 15.0827 7.54102H8.61869C8.1901 7.54102 7.77906 7.71127 7.476 8.01433C7.17294 8.31739 7.00269 8.72843 7.00269 9.15702C7.00269 9.58562 7.17294 9.99665 7.476 10.2997C7.77906 10.6028 8.1901 10.773 8.61869 10.773Z"
      fill="url(#paint1_linear_42_1969)"
    />
    <path
      d="M23.7015 10.773H30.1655C30.5941 10.773 31.0051 10.6028 31.3082 10.2997C31.6112 9.99665 31.7815 9.58562 31.7815 9.15702C31.7815 8.72843 31.6112 8.31739 31.3082 8.01433C31.0051 7.71127 30.5941 7.54102 30.1655 7.54102H23.7015C23.2729 7.54102 22.8618 7.71127 22.5588 8.01433C22.2557 8.31739 22.0854 8.72843 22.0854 9.15702C22.0854 9.58562 22.2557 9.99665 22.5588 10.2997C22.8618 10.6028 23.2729 10.773 23.7015 10.773Z"
      fill="url(#paint2_linear_42_1969)"
    />
    <path
      d="M80.8105 34.6205C80.4243 34.4308 79.9788 34.4011 79.5708 34.5378C79.1628 34.6745 78.8251 34.9665 78.631 35.3506L72.0926 48.4276C71.9237 48.8117 71.909 49.2459 72.0515 49.6405C72.194 50.0351 72.4827 50.3599 72.8579 50.5475C73.2332 50.7351 73.6662 50.7713 74.0674 50.6485C74.4685 50.5257 74.8071 50.2534 75.0131 49.8878L81.5516 36.8109C81.6468 36.6184 81.7032 36.4091 81.7175 36.1948C81.7318 35.9805 81.7037 35.7656 81.6349 35.5622C81.5661 35.3587 81.4578 35.1709 81.3164 35.0093C81.175 34.8477 81.0031 34.7156 80.8105 34.6205Z"
      fill="url(#paint3_linear_42_1969)"
    />
    <path
      d="M97.033 41.4644L89.4047 33.8362C89.0949 33.5474 88.685 33.3902 88.2616 33.3977C87.8381 33.4052 87.434 33.5767 87.1345 33.8762C86.835 34.1757 86.6635 34.5798 86.656 35.0032C86.6486 35.4267 86.8057 35.8366 87.0945 36.1464L93.5676 42.6195L87.0945 49.0926C86.9339 49.2423 86.8051 49.4228 86.7157 49.6233C86.6264 49.8238 86.5783 50.0402 86.5745 50.2597C86.5706 50.4792 86.611 50.6972 86.6932 50.9007C86.7754 51.1043 86.8978 51.2892 87.053 51.4444C87.2082 51.5996 87.3931 51.722 87.5966 51.8042C87.8002 51.8864 88.0182 51.9268 88.2377 51.9229C88.4572 51.919 88.6736 51.871 88.8741 51.7817C89.0746 51.6923 89.2551 51.5635 89.4047 51.4029L97.033 43.7747C97.3391 43.4682 97.511 43.0527 97.511 42.6195C97.511 42.1864 97.3391 41.7709 97.033 41.4644Z"
      fill="url(#paint4_linear_42_1969)"
    />
    <path
      d="M66.4383 34.1626C66.1318 33.8565 65.7163 33.6846 65.2831 33.6846C64.85 33.6846 64.4345 33.8565 64.128 34.1626L56.4998 41.7909C56.1937 42.0973 56.0217 42.5128 56.0217 42.946C56.0217 43.3792 56.1937 43.7946 56.4998 44.1011L64.128 51.7294C64.4379 52.0181 64.8477 52.1753 65.2712 52.1678C65.6947 52.1603 66.0987 51.9888 66.3982 51.6893C66.6977 51.3898 66.8693 50.9858 66.8767 50.5623C66.8842 50.1388 66.727 49.729 66.4383 49.4191L59.8998 42.8806L66.4383 36.3421C66.7063 36.0424 66.8545 35.6545 66.8545 35.2524C66.8545 34.8503 66.7063 34.4623 66.4383 34.1626Z"
      fill="url(#paint5_linear_42_1969)"
    />
    <defs>
      <linearGradient id="paint0_linear_42_1969" x1="25.8291" y1="13.4667" x2="71.0729" y2="105.46" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FE9940" />
        <stop offset="1" stop-color="#E5683B" />
      </linearGradient>
      <linearGradient id="paint1_linear_42_1969" x1="10.2768" y1="8.18229" x2="11.2487" y2="13.443" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FE9940" />
        <stop offset="1" stop-color="#E5683B" />
      </linearGradient>
      <linearGradient id="paint2_linear_42_1969" x1="25.3596" y1="8.18229" x2="26.3315" y2="13.443" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FE9940" />
        <stop offset="1" stop-color="#E5683B" />
      </linearGradient>
      <linearGradient id="paint3_linear_42_1969" x1="76.8377" y1="34.4531" x2="76.8377" y2="50.7201" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FE9940" />
        <stop offset="1" stop-color="#FF7F00" />
      </linearGradient>
      <linearGradient id="paint4_linear_42_1969" x1="92.0426" y1="33.3975" x2="92.0426" y2="51.9232" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FE9940" />
        <stop offset="1" stop-color="#FF7F00" />
      </linearGradient>
      <linearGradient id="paint5_linear_42_1969" x1="61.4494" y1="33.6846" x2="61.4494" y2="52.1681" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FE9940" />
        <stop offset="1" stop-color="#FF7F00" />
      </linearGradient>
    </defs>
  </svg>
</template>
