<template>
  <section class="promo-section dark-bg">
    <div class="container">
      <div class="promo">
        <div class="promo__wrapper">
          <div class="promo__content">
            <h1 class="promo__title" data-aos="fade-right" data-aos-duration="800" data-aos-delay="0">{{ slogan }}</h1>
            <p class="promo__subtitle text">Отраслевые ERP-системы, CRM-системы, автоматизация отделов продаж, внедрение ИИ-технологий</p>
            <nuxt-link :to="{ path: '/', hash: '#request' }" class="button animated-btn">Обсудить проект</nuxt-link>
          </div>
          <picture class="promo__img">
            <source type="image/webp" srcset="/img/bg/promo-mobile.webp" media="(max-width: 500px)" />
            <source type="image/png" srcset="/img/bg/promo-mobile.png" media="(max-width: 500px)" />
            <nuxt-img src="/img/bg/promo.png" format="webp" quality="80" class="img" />
          </picture>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
const slogan = 'Разработка \n российского ПО \n для автоматизации \n бизнеса';
</script>

<style scoped lang="scss">
.promo-section {
  position: relative;
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 12px;
    z-index: 1;
    right: -280px;
    width: 907px;
    height: 907px;
    background: radial-gradient(42.85% 42.85% at 50% 50%, #88532a 0%, transparent 100%);
  }
}

.promo {
  position: relative;
  z-index: 2;
  &__wrapper {
    padding: 68px 0 114px;
    display: flex;
    align-items: flex-end;
    gap: 40px;
  }

  &__content {
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: 56px;
    align-items: flex-start;
    flex-grow: 1;
    max-width: 50%;
  }

  &__title {
    font-size: 70px;
    line-height: 80px;
    font-weight: var(--ultra-bold);
    color: var(--white);
    text-wrap: nowrap !important;
    white-space: pre-line;
  }

  &__img {
    flex-grow: 1;
    display: flex;
    max-width: 50%;

    .img {
      object-fit: contain;
      width: 100%;
    }
  }
}

@media (max-width: 1024px) {
  .promo__wrapper {
    padding: 70px 0 0;
  }
  .promo {
    &__wrapper {
      align-items: center;
    }
    &__img {
      object-fit: contain;
      width: 550px;
      max-width: 50%;
    }

    &__title {
      font-size: 40px;
      line-height: 48px;
    }

    &__content {
      width: 464px;
      max-width: 50%;
      gap: 32px;
      padding-bottom: 70px;
      margin-right: 0;
    }
  }

  .promo-section {
    &::after {
      width: 607px;
      height: 607px;
      right: -200px;
    }
  }
}

@media (max-width: 767px) {
  .promo-section {
    &::after {
      right: -200px;
      bottom: -200px;
      top: auto;
    }
  }

  .promo {
    &__wrapper {
      flex-direction: column;
      align-items: flex-start;
      padding: 32px 0 0;
      gap: 30px;
    }

    &__title {
      text-wrap: wrap !important;
      white-space: normal;
    }

    &__content {
      width: 100%;
      max-width: 100%;
      padding-bottom: 0;
    }

    &__img {
      max-width: 100%;
      width: 100%;
    }
  }
}

@media (max-width: 350px) {
  .promo {
    &__title {
      font-size: 2rem;
      line-height: 1.2;
    }
  }
}
</style>
