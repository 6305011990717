<template>
  <nuxt-link to="/" @click="onClickLogo">
    <div class="main-logo">
      <svg width="140" height="52" viewBox="0 0 140 52" fill="none" xmlns="http://www.w3.org/2000/svg" class="main-logo__icon">
        <path
          d="M14.6151 18.8209H23.6831V14.2938H0.617432V18.8209H9.6897V33.8258H14.6151V18.8209ZM0.617432 5.00225H23.6831V0.475098H0.617432V5.00225Z"
          fill="#F89344"
        />
        <path
          d="M51.6752 29.3026H34.8297V19.2954H47.0121V14.7682H34.8297V5.00225H51.6752V0.475098H29.9043V33.8258H51.6752V29.3026Z"
          fill="#F89344"
        />
        <path
          d="M75.2609 10.0039C75.2609 12.8625 72.9294 15.0094 69.8193 15.0094H57.3788V33.8258H62.3042V19.5326H68.0039L75.519 33.8258H80.9649L72.9294 19.0582C72.9294 19.0582 80.1863 16.9152 80.1863 10.0039C80.1863 4.76502 75.519 0.475098 69.8193 0.475098H53.2319V5.00225H69.8193C72.9294 5.00225 75.2609 7.14524 75.2609 10.0039Z"
          fill="#F89344"
        />
        <path
          d="M105.067 10.0039C105.067 12.8625 102.731 15.0094 99.6253 15.0094H87.701V33.8258H92.6265V19.5326H99.6253C105.325 19.5326 109.992 15.2467 109.992 10.0039C109.992 4.76107 105.325 0.475098 99.6253 0.475098H83.5542V5.00225H99.6253C102.731 5.00225 105.067 7.14524 105.067 10.0039Z"
          fill="#F89344"
        />
        <path
          d="M115.694 23.3435C115.694 29.5392 120.877 34.3036 127.614 34.3036C134.35 34.3036 139.538 29.5392 139.538 23.3435V10.9561C139.538 4.76438 134.355 0 127.614 0C120.873 0 115.694 4.76438 115.694 10.9561V23.3435ZM134.613 23.3435C134.613 26.9178 131.503 29.7764 127.614 29.7764C123.725 29.7764 120.619 26.9178 120.619 23.3435V10.9561C120.619 7.38578 123.729 4.52715 127.614 4.52715C131.498 4.52715 134.613 7.38578 134.613 10.9561V23.3435Z"
          fill="#F89344"
        />
        <path
          d="M12.5437 50.2847H9.44648V51.8284H12.5437C14.0923 51.8284 15.1247 50.7993 15.1247 49.2556C15.1247 46.597 10.823 47.1973 10.823 45.9109C10.823 45.3963 11.2532 44.9675 11.7694 44.9675H14.6085V43.4238H11.7694C10.3068 43.4238 9.27441 44.453 9.27441 45.9109C9.27441 48.5695 13.5761 47.9692 13.5761 49.2556C13.5761 49.8559 13.1459 50.2847 12.5437 50.2847Z"
          fill="white"
        />
        <path
          d="M31.3655 46.4261C31.3655 44.5393 30.075 43.2529 28.1823 43.2529C26.2895 43.2529 24.999 44.5393 24.999 46.4261V48.8274C24.999 50.7141 26.2895 52.0006 28.1823 52.0006C30.075 52.0006 31.3655 50.7141 31.3655 48.8274V46.4261ZM26.5476 46.4261C26.5476 45.4827 27.2359 44.7966 28.1823 44.7966C29.1286 44.7966 29.8169 45.4827 29.8169 46.4261V48.8274C29.8169 49.7708 29.1286 50.4569 28.1823 50.4569C27.2359 50.4569 26.5476 49.7708 26.5476 48.8274V46.4261Z"
          fill="white"
        />
        <path
          d="M41.7727 50.2846V51.8283H46.4185V50.2846H44.8699V44.9674H46.5906V43.4237H44.8699V42.7376C44.8699 41.9658 45.4722 41.3655 46.2465 41.3655H47.1068V39.8218H46.2465C44.5258 39.8218 43.3213 41.0224 43.3213 42.7376V43.4237H41.7727V44.9674H43.3213V50.2846H41.7727Z"
          fill="white"
        />
        <path
          d="M59.0481 48.9123C59.0481 50.6275 60.2526 51.8281 61.9733 51.8281H62.8336V50.2844H61.9733C61.199 50.2844 60.5967 49.6841 60.5967 48.9123V44.9673H62.3174V43.4235H60.5967V40.6792H59.0481V43.4235H57.4995V44.9673H59.0481V48.9123Z"
          fill="white"
        />
        <path
          d="M79.0744 46.2539H77.6979L75.9772 49.3413V43.4238H73.2241V44.9675H74.4286V51.8284H76.1493L78.3861 47.7976L80.623 51.8284H82.3437V43.4238H80.7951V49.3413L79.0744 46.2539Z"
          fill="white"
        />
        <path
          d="M94.6765 51.8284H98.2899V46.1682C98.2899 44.5387 97.1715 43.4238 95.5368 43.4238H93.2999V44.9675H95.5368C96.2251 44.9675 96.7413 45.4821 96.7413 46.1682V46.6828H94.6765C93.1279 46.6828 92.0955 47.7119 92.0955 49.2556C92.0955 50.7993 93.1279 51.8284 94.6765 51.8284ZM94.6765 48.2265H96.7413V50.2847H94.6765C94.0742 50.2847 93.6441 49.8559 93.6441 49.2556C93.6441 48.6553 94.0742 48.2265 94.6765 48.2265Z"
          fill="white"
        />
        <path
          d="M113.515 51.829V50.2853H111.966V45.8258C111.966 45.8258 112.655 44.9682 113.687 44.9682H114.719V43.2529H114.031C112.655 43.2529 111.966 44.2821 111.966 44.2821V43.4244H109.213V44.9682H110.418V50.2853H108.869V51.829H113.515Z"
          fill="white"
        />
        <path
          d="M124.594 48.9132C124.594 50.6284 125.798 51.829 127.519 51.829H130.272V50.2853H127.519C126.745 50.2853 126.142 49.685 126.142 48.9132V48.4843H130.788V46.3403C130.788 44.5393 129.498 43.2529 127.691 43.2529C125.884 43.2529 124.594 44.5393 124.594 46.3403V48.9132ZM127.691 44.7966C128.551 44.7966 129.24 45.4827 129.24 46.3403V47.0264H126.142V46.3403C126.142 45.4827 126.831 44.7966 127.691 44.7966Z"
          fill="white"
        />
      </svg>
    </div>
  </nuxt-link>
</template>

<script setup lang="ts">
const onClickLogo = () => {
  window.scrollTo(0, 0);
};
</script>

<style scoped lang="scss">
.main-logo {
  width: 140px;
  height: 52px;

  &--decor {
    display: flex;
    width: auto;
    align-items: center;
  }

  &__img {
    height: 100%;
    object-fit: contain;
  }

  &__icon {
    width: 100%;
  }
}

@media (max-width: 1200px) {
  .main-header {
    .main-logo {
      width: 100px;
      height: auto;

      &--decor {
        width: auto;
        height: 40px;

        .main-logo__icon {
          height: 100%;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .main-logo {
    width: 135px;
    height: 52px;
  }
}
</style>
