<template>
  <svg width="77" height="71" viewBox="0 0 77 71" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M69.3021 0H6.77705C3.06319 0 0.0900938 2.43 0 5.58V51.32C0 54.45 3.00313 57 6.77705 57H36.0375V68H18.5193C18.121 68 17.7391 68.158 17.4575 68.4393C17.1759 68.7206 17.0177 69.1022 17.0177 69.5C17.0177 69.8978 17.1759 70.2794 17.4575 70.5607C17.7391 70.842 18.121 71 18.5193 71H57.5599C57.9581 71 58.3401 70.842 58.6217 70.5607C58.9033 70.2794 59.0615 69.8978 59.0615 69.5C59.0615 69.1022 58.9033 68.7206 58.6217 68.4393C58.3401 68.158 57.9581 68 57.5599 68H39.0406V57H69.3021C73.076 57 76.0792 54.45 76.0792 51.32V5.68C76.0792 2.55 73.076 0 69.3021 0ZM73.076 51.32C73.076 52.77 71.3443 54 69.3021 54H6.77705C4.77497 54 3.00313 52.77 3.00313 51.32V5.62C3.00313 4.18 4.73493 3 6.77705 3H69.3021C71.3042 3 73.076 4.23 73.076 5.68V51.32Z"
      fill="url(#paint0_linear_42_2012)"
    />
    <path
      d="M43.314 19.6303C42.9616 19.4548 42.5543 19.4245 42.1799 19.5461C41.8054 19.6677 41.4937 19.9313 41.3119 20.2803L33.2235 36.0003C33.1335 36.1756 33.0789 36.3669 33.0628 36.5633C33.0468 36.7597 33.0696 36.9573 33.1301 37.1449C33.1905 37.3325 33.2873 37.5063 33.415 37.6565C33.5427 37.8067 33.6987 37.9303 33.8742 38.0203C34.0497 38.1102 34.2412 38.1648 34.4378 38.1808C34.6344 38.1968 34.8322 38.174 35.02 38.1136C35.2078 38.0533 35.3818 37.9566 35.5322 37.829C35.6825 37.7015 35.8062 37.5456 35.8963 37.3703L43.9647 21.6203C44.1373 21.2693 44.1659 20.8649 44.0443 20.4931C43.9228 20.1214 43.6607 19.8118 43.314 19.6303Z"
      fill="url(#paint1_linear_42_2012)"
    />
    <path
      d="M52.9141 19.0803C52.6294 18.8154 52.253 18.6711 51.8639 18.678C51.4749 18.6848 51.1038 18.8422 50.8287 19.1171C50.5536 19.3919 50.396 19.7627 50.3891 20.1513C50.3822 20.5399 50.5266 20.916 50.7919 21.2003L58.2797 28.6703L50.7919 36.1403C50.6444 36.2776 50.526 36.4432 50.444 36.6272C50.3619 36.8112 50.3178 37.0099 50.3142 37.2113C50.3106 37.4127 50.3477 37.6127 50.4232 37.7995C50.4988 37.9863 50.6112 38.156 50.7538 38.2984C50.8963 38.4408 51.0662 38.5531 51.2532 38.6286C51.4401 38.704 51.6404 38.7411 51.842 38.7375C52.0436 38.7339 52.2425 38.6899 52.4267 38.6079C52.6108 38.5259 52.7766 38.4077 52.9141 38.2603L61.463 29.7303C61.7442 29.4491 61.9021 29.0678 61.9021 28.6703C61.9021 28.2728 61.7442 27.8916 61.463 27.6103L52.9141 19.0803Z"
      fill="url(#paint2_linear_42_2012)"
    />
    <path
      d="M26.4269 19.0803C26.1454 18.7994 25.7637 18.6416 25.3658 18.6416C24.9679 18.6416 24.5862 18.7994 24.3047 19.0803L15.7558 27.6203C15.4746 27.9015 15.3167 28.2828 15.3167 28.6803C15.3167 29.0778 15.4746 29.459 15.7558 29.7403L24.3047 38.2803C24.5893 38.5452 24.9658 38.6895 25.3548 38.6826C25.7438 38.6758 26.115 38.5184 26.3901 38.2435C26.6652 37.9687 26.8228 37.5979 26.8297 37.2093C26.8365 36.8207 26.6921 36.4446 26.4269 36.1603L18.9391 28.6903L26.4269 21.2003C26.7081 20.919 26.866 20.5378 26.866 20.1403C26.866 19.7428 26.7081 19.3615 26.4269 19.0803Z"
      fill="url(#paint3_linear_42_2012)"
    />
    <defs>
      <linearGradient id="paint0_linear_42_2012" x1="38.0396" y1="0" x2="38.0396" y2="71" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FE9940" />
        <stop offset="1" stop-color="#FF8011" />
      </linearGradient>
      <linearGradient id="paint1_linear_42_2012" x1="38.5882" y1="19.4727" x2="38.5882" y2="38.1858" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FE9940" />
        <stop offset="1" stop-color="#FF8011" />
      </linearGradient>
      <linearGradient id="paint2_linear_42_2012" x1="56.108" y1="18.6777" x2="56.108" y2="38.7377" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FE9940" />
        <stop offset="1" stop-color="#FF8011" />
      </linearGradient>
      <linearGradient id="paint3_linear_42_2012" x1="21.0913" y1="18.6416" x2="21.0913" y2="38.6829" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FE9940" />
        <stop offset="1" stop-color="#FF8011" />
      </linearGradient>
    </defs>
  </svg>
</template>
