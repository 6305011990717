<template>
  <svg width="55" height="88" viewBox="0 0 55 88" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M49.7363 0.00160129H4.88563C3.62537 -0.0310626 2.40354 0.437111 1.48779 1.30356C0.572046 2.17001 0.0370494 3.3641 0 4.62424V83.381C0.0370494 84.6412 0.572046 85.8353 1.48779 86.7017C2.40354 87.5682 3.62537 88.0363 4.88563 88.0037H49.7363C50.9966 88.0363 52.2184 87.5682 53.1342 86.7017C54.0499 85.8353 54.5849 84.6412 54.622 83.381V4.62424C54.5849 3.3641 54.0499 2.17001 53.1342 1.30356C52.2184 0.437111 50.9966 -0.0310626 49.7363 0.00160129ZM3.03455 13.1513H51.5874V69.7963H3.03455V13.1513ZM4.88563 3.03616H49.7363C50.1915 3.00533 50.6405 3.1545 50.9867 3.45154C51.333 3.74857 51.5487 4.16971 51.5874 4.62424V10.1168H3.03455V4.62424C3.0733 4.16971 3.28901 3.74857 3.63523 3.45154C3.98146 3.1545 4.43049 3.00533 4.88563 3.03616ZM49.7363 84.9691H4.88563C4.43049 84.9999 3.98146 84.8508 3.63523 84.5537C3.28901 84.2567 3.0733 83.8356 3.03455 83.381V72.8309H51.5874V83.381C51.5487 83.8356 51.333 84.2567 50.9867 84.5537C50.6405 84.8508 50.1915 84.9999 49.7363 84.9691Z"
      fill="url(#paint0_linear_42_1994)"
    />
    <path
      d="M27.8159 81.9345C29.2125 81.9345 30.3447 80.8024 30.3447 79.4057C30.3447 78.0091 29.2125 76.877 27.8159 76.877C26.4193 76.877 25.2871 78.0091 25.2871 79.4057C25.2871 80.8024 26.4193 81.9345 27.8159 81.9345Z"
      fill="url(#paint1_linear_42_1994)"
    />
    <path
      d="M31.0232 35.0606C30.6646 34.8846 30.2512 34.857 29.8724 34.9839C29.4937 35.1107 29.1803 35.3818 29.0001 35.7383L22.931 47.8766C22.7743 48.2331 22.7607 48.6362 22.8929 49.0024C23.0251 49.3687 23.2932 49.6701 23.6415 49.8443C23.9898 50.0184 24.3917 50.052 24.7641 49.938C25.1364 49.824 25.4507 49.5713 25.6419 49.232L31.711 37.0938C31.7994 36.9151 31.8517 36.7208 31.865 36.5219C31.8783 36.323 31.8522 36.1235 31.7883 35.9347C31.7245 35.7459 31.624 35.5715 31.4927 35.4215C31.3614 35.2716 31.2019 35.1489 31.0232 35.0606Z"
      fill="url(#paint2_linear_42_1994)"
    />
    <path
      d="M46.0847 41.4136L39.004 34.333C38.7164 34.065 38.336 33.9191 37.9429 33.926C37.5498 33.933 37.1748 34.0922 36.8968 34.3702C36.6188 34.6482 36.4596 35.0232 36.4526 35.4163C36.4457 35.8094 36.5916 36.1898 36.8596 36.4774L42.868 42.4858L36.8596 48.4943C36.7106 48.6332 36.591 48.8007 36.5081 48.9868C36.4251 49.1729 36.3805 49.3738 36.3769 49.5775C36.3734 49.7813 36.4108 49.9836 36.4871 50.1726C36.5634 50.3615 36.677 50.5331 36.8211 50.6772C36.9652 50.8213 37.1368 50.9349 37.3257 51.0112C37.5147 51.0875 37.717 51.1249 37.9208 51.1214C38.1245 51.1178 38.3254 51.0732 38.5115 50.9902C38.6976 50.9073 38.8651 50.7877 39.004 50.6387L46.0847 43.558C46.3688 43.2736 46.5284 42.8879 46.5284 42.4858C46.5284 42.0838 46.3688 41.6981 46.0847 41.4136Z"
      fill="url(#paint3_linear_42_1994)"
    />
    <path
      d="M17.6824 34.6361C17.398 34.352 17.0123 34.1924 16.6102 34.1924C16.2082 34.1924 15.8225 34.352 15.538 34.6361L8.45741 41.7167C8.17327 42.0012 8.01367 42.3869 8.01367 42.789C8.01367 43.191 8.17327 43.5767 8.45741 43.8612L15.538 50.9418C15.8257 51.2098 16.2061 51.3557 16.5992 51.3488C16.9922 51.3418 17.3673 51.1826 17.6453 50.9046C17.9233 50.6266 18.0825 50.2516 18.0894 49.8585C18.0964 49.4654 17.9505 49.085 17.6824 48.7974L11.6133 42.7283L17.6824 36.6592C17.9313 36.381 18.0688 36.0209 18.0688 35.6476C18.0688 35.2744 17.9313 34.9143 17.6824 34.6361Z"
      fill="url(#paint4_linear_42_1994)"
    />
    <defs>
      <linearGradient id="paint0_linear_42_1994" x1="27.311" y1="0" x2="27.311" y2="88.0053" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FE9940" />
        <stop offset="1" stop-color="#FF7F00" />
      </linearGradient>
      <linearGradient id="paint1_linear_42_1994" x1="27.8159" y1="76.877" x2="27.8159" y2="81.9345" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FE9940" />
        <stop offset="1" stop-color="#FF7F00" />
      </linearGradient>
      <linearGradient id="paint2_linear_42_1994" x1="27.3355" y1="34.9053" x2="27.3355" y2="50.0045" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FE9940" />
        <stop offset="1" stop-color="#FF7F00" />
      </linearGradient>
      <linearGradient id="paint3_linear_42_1994" x1="41.4526" y1="33.9258" x2="41.4526" y2="51.1216" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FE9940" />
        <stop offset="1" stop-color="#FF7F00" />
      </linearGradient>
      <linearGradient id="paint4_linear_42_1994" x1="13.0517" y1="34.1924" x2="13.0517" y2="51.349" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FE9940" />
        <stop offset="1" stop-color="#FF7F00" />
      </linearGradient>
    </defs>
  </svg>
</template>
