<template>
  <section class="section dark-bg">
    <div class="container">
      <div class="blog">
        <h2 class="title title--invert blog__title">Новости</h2>
        <div class="articles">
          <ul class="articles__list" v-if="!errorMessage">
            <carousel :items-to-show="2" :breakpoints="breakpoints">
              <slide v-for="(item, index) in articles" :key="`project-${index}`">
                <div class="articles__slide">
                  <div class="slide">
                    <div v-if="item.attributes.thumbnail.data" class="slide__img-wrapper">
                      <nuxt-img :src="mediaPath + item.attributes.thumbnail.data.attributes.url" class="slide__img blog__img" />
                    </div>
                    <div class="slide__content">
                      <h3 class="slide__title">{{ item.attributes.title }}</h3>
                      <p class="slide__text">{{ item.attributes.description }}</p>
                      <LinkBtn :to="'/news/' + item.attributes.seoUrl" title="Перейти к статье">Подробнее</LinkBtn>
                    </div>
                  </div>
                </div>
              </slide>
              <template #addons>
                <div class="carousel-nav">
                  <Navigation />
                </div>
              </template>
            </carousel>
          </ul>
          <div v-else>{{ errorMessage }}</div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import LinkBtn from '~/components/buttons/link-btn.vue';
const { find } = useStrapi();
const mediaPath = useStrapiMedia();
const route = useRoute();
const errorMessage = ref<string>('');
let articles = null;

const breakpoints = {
  300: {
    itemsToShow: 1,
    snapAlign: 'start',
  },
  500: {
    itemsToShow: 1,
    snapAlign: 'start',
  },

  768: {
    itemsToShow: 2,
    snapAlign: 'start',
  },
  1024: {
    itemsToShow: 2,
    snapAlign: 'start',
  },
};

try {
  const response = await find('tnews', {
    publicationState: 'live',
    populate: '*',
    sort: 'publishedAt:desc',
    pagination: route.path === '/news' ? { start: 0 } : { start: 0, limit: 4 },
  });

  articles = response.data;
} catch (error) {
  errorMessage.value = 'Не удалось загрузить ленту новостей';
}
</script>

<style scoped lang="scss">
.blog {
  &__title {
    margin-bottom: 36px;
  }

  &__img {
    height: 362px;
    object-fit: contain;
    background-color: #fff;
  }
}
.articles {
  &__slide {
    padding: 0 37px;
    height: 100%;
  }

  &__list {
    margin: 0 -37px;
  }

  .carousel-nav {
    right: 37px;
  }
}
</style>
