<template>
  <section class="section dark-bg projects-section">
    <div class="container">
      <div class="projects">
        <h2 class="title title--invert projects__title">Портфолио</h2>
        <div class="projects__list">
          <carousel :items-to-show="2" :breakpoints="breakpoints">
            <slide v-for="(item, index) in projectsList" :key="`project-${index}`">
              <project-card :data="item" />
            </slide>
            <template #addons>
              <div class="carousel-nav">
                <Navigation />
              </div>
            </template>
          </carousel>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import projectCard from '~/components/blocks/project-card.vue';

const projectsList = [
  {
    title: 'Borasco. MES-система для производителя \n USB-кабелей',
    text: 'Цифровизация производства для точного учета, планирования и оптимизации затрат',
    img: '/img/index/erp_cable.png',
    linkTo: '/cable-production',
  },
  {
    title: 'Разработка сервисов для селлеров маркетплейсов',
    text: 'Автоответчики для быстрой обработки вопросов и отзывов покупателей',
    img: '/img/index/sa.png',
    linkTo: '/progress',
  },
  {
    title: 'Внедрение \n ERP-системы',
    text: 'Внедрение ERP для таможенного брокера и мультимодального перевозчика',
    img: '/img/index/erp.jpg',
    linkTo: '/progress',
  },
];

const breakpoints = {
  300: {
    itemsToShow: 1,
    snapAlign: 'start',
  },
  500: {
    itemsToShow: 1,
    snapAlign: 'start',
  },

  768: {
    itemsToShow: 2,
    snapAlign: 'start',
  },
  1024: {
    itemsToShow: 2,
    snapAlign: 'start',
  },
};
</script>

<style scoped lang="scss">
@use '~/assets/styles/mixins';

.projects {
  position: relative;
  z-index: 2;
  .carousel__slide {
    padding: 0 37px;
    box-sizing: border-box;
  }

  &__list {
    margin: 0 -37px;
  }
}

@media (max-width: 767px) {
  .projects {
    .carousel {
      padding-top: 60px;
    }
    .carousel__slide {
      padding: 10px;
      box-sizing: border-box;
    }

    &__list {
      margin: 0 -10px;
    }
  }
}

@media (max-width: 500px) {
  .projects {
    &__title {
      margin-bottom: 0;
    }
  }
}
</style>
