<template>
  <section class="dark-bg intro-section">
    <div class="section intro">
      <div class="container">
        <div class="intro__content text">
          <p>TERPO software – аккредитованная IT-компания из Санкт-Петербурга.</p>
          <p>
            С 2021 года разрабатываем программное обеспечение для производственных и логистических компаний, адаптированные под задачи
            бизнеса.
          </p>
          <p>
            Мы подключаемся, когда стандартные решения не оправдали ожиданий, или компания уже вложила значительные ресурсы в другие
            продукты, но не получила желаемый результат.
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped lang="scss">
.intro {
  position: relative;
  z-index: 0;
  background: linear-gradient(267.86deg, transparent 66.28%, rgba(255, 127, 0, 0.4) 95.09%),
    linear-gradient(89.89deg, rgba(50, 50, 50, 0) 21%, #323232 49.94%);
  &-section {
    position: relative;
    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 50%;
      background-image: url('/img/bg/intro-bg.jpg');
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      z-index: 0;
    }
  }

  &__content {
    width: 50%;
    margin-left: auto;
    display: flex;
    flex-direction: column;
    gap: var(--standard-line);
  }
}
@media (min-width: 1024px) and (max-width: 1200px) {
  .intro {
    &__content {
      font-size: 20px;
      line-height: 26px;
    }
  }
}

@media (max-width: 767px) {
  .intro {
    background: none;
    z-index: 1;
    &-section {
      &::before {
        width: 100%;
        opacity: 0.1;
      }
    }
    &__content {
      width: 100%;
    }
  }
}
</style>
<script setup lang="ts"></script>
