<template>
  <div class="modal">
    <button class="modalBtn" aria-label="Закрыть" @click="emits('close')">
      <svg width="30px" height="30px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M19.207 6.207a1 1 0 0 0-1.414-1.414L12 10.586 6.207 4.793a1 1 0 0 0-1.414 1.414L10.586 12l-5.793 5.793a1 1 0 1 0 1.414 1.414L12 13.414l5.793 5.793a1 1 0 0 0 1.414-1.414L13.414 12l5.793-5.793z"
          fill="#ffffff"
        />
      </svg>
    </button>
    <Transition name="show">
      <div v-if="showSlot" ref="modalSlotRef">
        <slot></slot>
      </div>
    </Transition>
  </div>
</template>

<script setup lang="ts">
import { onClickOutside } from '@vueuse/core';
interface IEmits {
  (event: 'close'): void;
}

const emits = defineEmits<IEmits>();
const modalSlotRef = ref(null);
const showSlot = ref(false);

onClickOutside(modalSlotRef, () => emits('close'));

onMounted(() => {
  showSlot.value = true;
});

onBeforeUnmount(() => {
  showSlot.value = false;
});
</script>

<style scoped lang="scss">
.modal {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
}

.modalBtn {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 50px;
  height: 50px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}
@media (max-width: 1024px) {
}

@media (max-width: 767px) {
  .modalBtn {
    top: 10px;
    right: 5px;
  }

  .modal {
    display: block;
  }
}

.show-enter-active {
  animation: showModal 0.5s ease;
}
.show-leave-active {
  animation: showModal 0.5s ease reverse;
}

@keyframes showModal {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
