<template>
  <svg width="63" height="89" viewBox="0 0 63 89" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M58.5703 0H4.3697C3.31018 0 2.29405 0.420895 1.54485 1.17009C0.795651 1.91929 0.374756 2.93542 0.374756 3.99495V84.5474C0.374756 85.6069 0.795651 86.623 1.54485 87.3722C2.29405 88.1214 3.31018 88.5423 4.3697 88.5423H58.5703C59.6298 88.5423 60.6459 88.1214 61.3951 87.3722C62.1443 86.623 62.5652 85.6069 62.5652 84.5474V3.99495C62.5652 2.93542 62.1443 1.91929 61.3951 1.17009C60.6459 0.420895 59.6298 0 58.5703 0ZM4.3697 3.16223H58.5703C58.7903 3.16496 59.0005 3.25357 59.1561 3.40915C59.3116 3.56472 59.4002 3.77494 59.403 3.99495V69.569H3.53698V3.99495C3.53698 3.77409 3.62472 3.56229 3.78088 3.40612C3.93705 3.24996 4.14885 3.16223 4.3697 3.16223ZM58.5703 85.3801H4.3697C4.1497 85.3774 3.93948 85.2888 3.7839 85.1332C3.62833 84.9776 3.53972 84.7674 3.53698 84.5474V72.7312H59.403V84.5474C59.4002 84.7674 59.3116 84.9776 59.1561 85.1332C59.0005 85.2888 58.7903 85.3774 58.5703 85.3801Z"
      fill="url(#paint0_linear_42_1981)"
    />
    <path
      d="M23.0359 12.6486H39.9012C40.3205 12.6486 40.7227 12.482 41.0192 12.1855C41.3157 11.8889 41.4823 11.4868 41.4823 11.0674C41.4823 10.6481 41.3157 10.2459 41.0192 9.94943C40.7227 9.65291 40.3205 9.48633 39.9012 9.48633H23.0359C22.6166 9.48633 22.2144 9.65291 21.9179 9.94943C21.6214 10.2459 21.4548 10.6481 21.4548 11.0674C21.4548 11.4868 21.6214 11.8889 21.9179 12.1855C22.2144 12.482 22.6166 12.6486 23.0359 12.6486Z"
      fill="url(#paint1_linear_42_1981)"
    />
    <path
      d="M31.4584 76.5255C30.9372 76.5276 30.4283 76.6842 29.9961 76.9754C29.5639 77.2667 29.2278 77.6796 29.0303 78.162C28.8328 78.6443 28.7827 79.1743 28.8865 79.6851C28.9902 80.1958 29.243 80.6644 29.613 81.0314C29.983 81.3985 30.4536 81.6476 30.9652 81.7472C31.4767 81.8469 32.0064 81.7926 32.4871 81.5912C32.9678 81.3898 33.378 81.0504 33.6658 80.6159C33.9537 80.1814 34.1062 79.6713 34.1041 79.1501C34.1041 78.8037 34.0355 78.4606 33.9023 78.1408C33.7691 77.821 33.5739 77.5307 33.3279 77.2867C33.082 77.0427 32.7901 76.8498 32.4693 76.7192C32.1484 76.5885 31.8048 76.5227 31.4584 76.5255Z"
      fill="url(#paint2_linear_42_1981)"
    />
    <path
      d="M15.5842 56.9206C15.6941 57.2315 15.8986 57.5003 16.1688 57.6893C16.4391 57.8783 16.7618 57.9781 17.0916 57.9746H45.8467C46.1765 57.9781 46.4992 57.8783 46.7694 57.6893C47.0397 57.5003 47.2441 57.2315 47.3541 56.9206L53.5309 37.9472C53.6087 37.7105 53.6282 37.4585 53.5879 37.2126C53.5475 36.9667 53.4484 36.7342 53.299 36.5347C53.1582 36.3246 52.9687 36.1517 52.7466 36.0305C52.5245 35.9094 52.2765 35.8437 52.0236 35.839H42.3367L38.3206 24.5288C38.2514 24.3329 38.1443 24.1526 38.0054 23.9982C37.8665 23.8437 37.6985 23.7181 37.5111 23.6285C37.3237 23.539 37.1204 23.4872 36.913 23.4762C36.7055 23.4652 36.4979 23.4951 36.3021 23.5643C36.1062 23.6335 35.9259 23.7407 35.7714 23.8796C35.6169 24.0185 35.4913 24.1864 35.4018 24.3739C35.3122 24.5613 35.2605 24.7645 35.2494 24.972C35.2384 25.1794 35.2684 25.387 35.3376 25.5829L38.9847 35.839H24.1749L27.7799 25.5723C27.9151 25.1767 27.8892 24.7436 27.7077 24.3669C27.5263 23.9902 27.2038 23.7 26.8101 23.5591C26.6143 23.4892 26.4066 23.459 26.1991 23.47C25.9915 23.481 25.7882 23.5332 25.6009 23.6234C25.4136 23.7136 25.2461 23.8401 25.1081 23.9955C24.9701 24.1509 24.8643 24.3322 24.7968 24.5288L20.823 35.839H10.9147C10.6655 35.839 10.4198 35.8982 10.1981 36.0119C9.9763 36.1255 9.78475 36.2903 9.63924 36.4926C9.48292 36.6967 9.37987 36.9366 9.33939 37.1906C9.29891 37.4445 9.32226 37.7045 9.40735 37.9472L15.5842 56.9206ZM19.7057 39.0013L18.4935 42.4376C18.3537 42.8331 18.3768 43.268 18.5577 43.6466C18.7385 44.0251 19.0624 44.3163 19.458 44.4561C19.8535 44.5959 20.2884 44.5728 20.667 44.3919C21.0455 44.2111 21.3367 43.8872 21.4765 43.4916L23.0576 39.0013H40.0915L41.6831 43.4916C41.7905 43.8 41.9914 44.0673 42.2578 44.2562C42.5242 44.4451 42.8428 44.5463 43.1694 44.5457C43.3487 44.5494 43.5272 44.5208 43.6964 44.4614C44.0887 44.3164 44.4082 44.0227 44.5858 43.644C44.7633 43.2653 44.7846 42.8319 44.6451 42.4376L43.454 39.0013H49.8417L44.6978 54.8124H18.2932L13.0966 39.0013H19.7057Z"
      fill="url(#paint3_linear_42_1981)"
    />
    <defs>
      <linearGradient id="paint0_linear_42_1981" x1="31.47" y1="0" x2="31.47" y2="88.5423" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FE9940" />
        <stop offset="1" stop-color="#FF7C0B" />
      </linearGradient>
      <linearGradient id="paint1_linear_42_1981" x1="31.4685" y1="9.48633" x2="31.4685" y2="12.6486" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FE9940" />
        <stop offset="1" stop-color="#FF7C0B" />
      </linearGradient>
      <linearGradient id="paint2_linear_42_1981" x1="31.4689" y1="76.5254" x2="31.4689" y2="81.7958" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FE9940" />
        <stop offset="1" stop-color="#FF7C0B" />
      </linearGradient>
      <linearGradient id="paint3_linear_42_1981" x1="31.4642" y1="23.4678" x2="31.4642" y2="57.9747" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FE9940" />
        <stop offset="1" stop-color="#FF7C0B" />
      </linearGradient>
    </defs>
  </svg>
</template>
