<template>
  <section class="news-detailed-header grey-bg">
    <div class="container">
      <nuxt-link class="news-detailed__link" to="/news"> Назад </nuxt-link>
      <h1 class="title">
        {{ data.attributes.title }}
      </h1>
    </div>
  </section>
  <section class="news-detailed">
    <div class="container">
      <div class="news-detailed__content">
        <ContentRendererMarkdown :value="content" />
        <div class="news-detailed__content-date">
          {{ useDateFormat(data.attributes.releaseDate, 'DD.MM.YYYY').value }}
        </div>
      </div>
    </div>
  </section>
  <contacts-section />
</template>

<script setup lang="ts">
import { useRoute } from '#app';
import markdownParser from '@nuxt/content/transformers/markdown';
import { useDateFormat } from '@vueuse/shared/index';
const route = useRoute();
const { findOne } = useStrapi();

const seoUrl = route.path.replace('/news/', '');
const response = await findOne('tnews', '', { filters: { seoUrl: seoUrl }, populate: '*' });
const data = response.data[0];
const content = await markdownParser.parse(null, data.attributes.content);

useHead({
  title: data.attributes.title,
  meta: [{ name: 'description', content: data.attributes.description }],
});
</script>

<style lang="scss">
.news-detailed {
  background: white;
  padding: 36px 36px 0;

  &-header {
    padding: 36px;
    .wrapper {
      max-width: 1088px;
      padding: 20px 0;
    }
  }

  &__link {
    display: flex;
    gap: 4px;
    align-items: center;
    color: var(--accent-color);
  }

  &__content {
    display: flex;
    flex-direction: column;
    .download {
      color: var(--accent-color);
      font-weight: 900;
    }

    &-date {
      color: var(--accent-color);
    }

    strong {
      font-weight: 900;
    }

    p {
      color: var(--additional-text-color);
    }

    img {
      max-width: 70%;
      color: var(--blue-text-color);
      border-radius: 16px;
      border: 1px solid var(--article-border);
    }

    ol {
      margin-left: 24px;
      color: var(--additional-text-color);
    }

    ul {
      margin-left: 24px;
      color: var(--additional-text-color);
    }

    li {
      margin-bottom: 12px;
    }

    h2 {
      margin-top: 32px;
      color: var(--additional-text-color);
    }

    h4 {
      color: var(--additional-text-color);
    }

    hr {
      margin-bottom: 40px;
      border: 1px solid #d9d9d9;
    }

    h5 {
      font-size: 24px;
      max-width: 300px;
      font-weight: 800;
      background-image: linear-gradient(to right, var(--blue), var(--lime));
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      -moz-background-clip: text;
      -moz-text-fill-color: transparent;
      background-size: 100%;
      background-repeat: repeat;
    }

    p,
    li {
      margin: 20px 0;
      line-height: var(--desktop-medium-line-heght);
    }
  }
}
@media (max-width: 500px) {
  .news-detailed {
    &__content {
      display: flex;
      flex-direction: column;
      gap: 18px;

      img {
        max-width: 100%;
      }
    }
  }
}

@media (min-width: 1024px) {
  .news-detailed {
    &__content {
      display: flex;
      flex-direction: column;
      .fullsize {
        max-width: 98%;
      }
      p:has(> img) {
        display: flex;
        img {
          max-width: 864px;
          margin: 0 auto;
        }
      }
    }
  }
}
</style>
