<template>
  <section class="section dark-bg advantages-section">
    <div class="container">
      <div class="advantages">
        <h2 class="title title--center title--invert advantages__title">Почему мы?</h2>
        <ul class="advantages__list" data-aos="fade-up" data-aos-duration="800" data-aos-delay="0">
          <li class="advantages__item" v-for="(item, index) in advantages" :key="`advantage-${index}`">
            <div class="advantage">
              <component class="advantage__icon" :is="item.icon" />
              <span class="advantage__text">{{ item.text }}</span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import Code from '~/components/icons/index/adv-code.vue';
import Cycle from '~/components/icons/index/cycle.vue';
import Pen from '~/components/icons/index/pen.vue';
import Calc from '~/components/icons/index/calc.vue';
import Settings from '~/components/icons/index/settings.vue';

const advantages = [
  {
    text: 'Российский разработчик с глубоким пониманием локального рынка',
    icon: Code,
  },
  {
    text: 'Полный цикл разработки: от идеи до технической поддержки',
    icon: Cycle,
  },
  {
    text: 'Интеграция ПО в существующие бизнес-процессы',
    icon: Pen,
  },
  {
    text: 'Гибкость и прозрачность в ценообразовании',
    icon: Calc,
  },
  {
    text: 'Модернизация и доработка ваших текущих IT-решений',
    icon: Settings,
  },
];
</script>

<style scoped lang="scss">
@use '~/assets/styles/mixins';
.advantages {
  &-section {
    position: relative;
    z-index: 2;
    &::before {
      content: '';
      display: block;
      width: 792px;
      height: 792px;
      position: absolute;
      @include mixins.radial-gradient;
      left: -270px;
      bottom: -280px;
    }
  }
  &__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 124px;
    padding: 130px 0 140px;
    position: relative;

    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-image: image-set(url('/img/index/work.webp') type('image/webp'), url('/img/index/work.png') type('image/png'));
      background-repeat: no-repeat;
      background-position: bottom left;
      background-size: 514px;
    }
  }

  &__item {
    position: relative;
    .advantage {
      height: 100%;
      border-right: 2px solid var(--accent-color);
    }
    &:nth-child(4) {
      grid-column-start: 2;
      grid-column-end: 3;
    }

    &:nth-child(3),
    &:nth-child(5) {
      .advantage {
        border-right: none;
      }
    }
  }
}

.advantage {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 44px;
  color: var(--white);
  padding: 0 68px 25px;
  width: 100%;
  box-sizing: border-box;
  text-align: center;
}

@media (max-width: 1024px) {
  .advantages {
    &__list {
      padding: 80px 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      row-gap: 70px;
      &::before {
        opacity: 0.2;
      }
    }

    &__item {
      width: 33%;
    }
  }

  .advantage {
    padding: 0 25px 25px;
  }
}

@media (max-width: 767px) {
  .advantages {
    &-section {
      &::before {
        bottom: -180px;
      }
    }
    &__list {
      padding: 36px 0;
    }

    &__item {
      width: 50%;
      .advantage {
        border-right: none;
      }

      &:nth-child(odd) {
        .advantage {
          border-right: 2px solid var(--accent-color);
        }
      }

      &:last-child {
        .advantage {
          border-right: none;
        }
      }
    }
  }

  .advantage {
    &__icon {
      width: 50px;
      height: 50px;
    }
  }
}

@media (max-width: 500px) {
  .advantages {
    column-gap: 36px;
    &__item {
      width: 100%;
    }

    &__list {
      &::before {
        background-size: contain;
      }
    }
  }

  .advantage {
    border-right: none !important;
    padding: 0 25px;
  }
}
</style>
