<template>
  <nuxt-link :to="props.to" :title="props.title" class="btn-link">
    <span class="btn-link__text"><slot></slot></span>
    <svg class="btn-link__icon" width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19 0.999999C19 0.447714 18.5523 -7.74732e-07 18 -1.02761e-06L9 -2.26823e-07C8.44772 -5.63998e-07 8 0.447715 8 0.999999C8 1.55228 8.44772 2 9 2L17 2L17 10C17 10.5523 17.4477 11 18 11C18.5523 11 19 10.5523 19 10L19 0.999999ZM1.70711 18.7071L18.7071 1.70711L17.2929 0.292893L0.292893 17.2929L1.70711 18.7071Z"
      />
    </svg>
  </nuxt-link>
</template>

<script setup lang="ts">
interface IProps {
  title: string;
  to: string;
}

const props = defineProps<IProps>();
</script>

<style scoped lang="scss">
@use '~/assets/styles/mixins';

.btn-link {
  display: block;
  @include mixins.gradient-border;
  padding: 14px 26px;
  color: var(--white);
  display: flex;
  align-items: center;
  gap: 10px;
  transition: color 0.3s ease;

  &__icon {
    fill: var(--white);
    transition: fill 0.3s ease;
  }

  &:hover {
    color: var(--accent-color);

    .btn-link {
      &__icon {
        fill: var(--accent-color);
      }
    }
  }
}

@media (max-width: 500px) {
  .btn-link {
    padding: 10px;

    &__icon {
      width: 15px;
      height: 15px;
    }
  }
}
</style>
